import { useState } from "react";

// Components
import COMP00004 from "./components/Wireframes/COMP00004";
import COMP00005 from "./components/Wireframes/COMP00005";
import COMP00016 from "./components/Wireframes/COMP00016";
import COMP00018 from "./components/Wireframes/COMP00018";
import COMP00020 from "./components/Wireframes/COMP00020";
import COMP00023 from "./components/Wireframes/COMP00023";
import COMP_ALLYA from "./components/Wireframes/COMP_ALLYA";
import CHEC00001 from "./components/Wireframes/CHEC00001";
import FAQS00001 from "./components/Wireframes/FAQS00001";
import COMP_GEOL from "./components/Wireframes/COMP_GEOL";
import FOOT00001 from "./components/Wireframes/FOOT00001";
import HEAD00002 from "./components/Wireframes/HEAD00002";

// Libs
import { BrowserRouter } from "react-router-dom";

// Components
import SectionCookies from "./components/SectionCookies";
import ButtonDonateNow from "./components/ButtonDonateNow";

// Translations
import { startI18N } from "./i18n";
import i18nTranslations from "./whitelabels/vitoriaregia/i18n";

function App() {
  const [acceptedCookies, setAcceptedCookies] = useState(false);

  startI18N(i18nTranslations);

  return (
    <div className="lp-app notranslate">
      <HEAD00002/>
      <COMP00016 name="COMP00016"/>
      <COMP00005/>
      <COMP00023/>
      <COMP00018/>
      <COMP00004/>
      <COMP00020/>
      <COMP_ALLYA/>
      <CHEC00001/>
      <FAQS00001/>
      <COMP_GEOL url="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.3817512596975!2d-47.80771732459149!3d-21.17698867834867!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94b9befd056d0b17%3A0x41d3768c38ddf92e!2sR.%20Cmte.%20Marcondes%20Salgado%2C%20269%20-%20Centro%2C%20Ribeir%C3%A3o%20Preto%20-%20SP%2C%2014010-150!5e0!3m2!1spt-BR!2sbr!4v1698695435504!5m2!1spt-BR!2sbr"/>
      <FOOT00001/>
      <ButtonDonateNow 
        primaryColor="#F4766A" 
        secondaryColor="#FFFFFF" 
        borderFixedColor="#FFFFFF" 
        borderFixedHoverColor="#F4766A" 
      />
      {SectionCookies != '' && acceptedCookies === false && (
        <SectionCookies onAccept={() => setAcceptedCookies(true)} />
      )}
    </div>
  );
}

const AppContainer = (props) => {
  return (
    <BrowserRouter>
      <App {...props} />
    </BrowserRouter>
  );
};

export default AppContainer;