import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";


// Styles
import { Wrapper, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  return (
    <Wrapper
      leftBackgroundImageURL="/assets/vitoria-regia/COMP00023-img.webp"
      id="COMP00023"
    >
      <Row className="g-0 h-100">
        <Col md={6} className="d-none d-md-block left" />
        <Col xs={12} md={6} className="right">
          <Col xs={10} sm={10} lg={10} xl={8} className="d-flex flex-column justify-content-center">
            <WrapperText titleColor="#FFF" dangerouslySetInnerHTML={{ __html: t('COMP00023.title') }}></WrapperText>
            <WrapperText 
              contentColor="#FFFFFF" 
              dangerouslySetInnerHTML={{ __html: t("COMP00023.line1") }} 
            />
            <WrapperText 
              contentColor="#FFFFFF" 
              dangerouslySetInnerHTML={{ __html: t("COMP00023.line2") }} 
            />
          </Col>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;