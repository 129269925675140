import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 660px;
  background-image: url(${({ backgroundImageURL }) => backgroundImageURL});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .logo {
    margin-top: 50px;
    position: relative;
  }

  .selo {
    position: absolute;
    right: 28%;
    top: 9%;

    @media ${devices.xs} {
      right: 7%;
    }

    @media ${devices.sm} {
      right: 15%;
    }

    @media ${devices.md} {
      right: 20%;
    }

    @media ${devices.lg} {
      right: 28%;
    }
  }

  .imageSelo {
    @media ${devices.xs} {
      display: none;
    }
  }

  h1 {
    margin-top: 50px;
    font-size: 32px;
    font-weight: 900;
    line-height: 36px;
    color: ${({ titleColor }) => titleColor};
  }

  .sub-title {
    color: ${({ titleColor }) => titleColor};
    font-size: 24px;
    font-weight: 500;
    line-height: 28px;
  }

  .button {
    margin-bottom: 50px;
  }

  .wrapper-button-i18n {
    position: absolute;
    width: 100%;
    z-index: 1;

    @media ${devices.xs} {
      top: 32px;
    }
    @media ${devices.sm} {
      top: 32px;
    }
    @media ${devices.md} {
      top: 50px;
    }
  }
`;

export const Color = styled.div`
  background-color: ${({ color }) => color};
  height: 13px;
  flex: 1;
`;