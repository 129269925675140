import styled from 'styled-components'
//import devices from '../../../utils/helperGrids'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 0;
  background-color: ${({ backgroundColor }) => backgroundColor};

  .row-title {
    margin-bottom: 0px;
  }

  .row-cards {
    margin-top: 20px;
  }
  
  .row-button {
    margin-top: 30px;
  }
`;

export const WrapperCard = styled.div`
  width: 250px;
  margin: 0 20px 50px;

  .number {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 68px;
    height: 68px;
    margin-top: -34px;
    margin-left: -34px;
    border-radius: 50%;
    background-color: ${({ mainColor }) => mainColor};
    color ${({ numberColor }) => numberColor};
    font-size: 28px;
    font-weight: 600;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 250px;
    min-height: 150px;
    padding: 18px;
    border-radius: 12px;
    background-color: ${({ cardBackgroundColor }) => cardBackgroundColor};
    text-align: center;
  }

  .content-percent {
    color: ${({ mainColor }) => mainColor};
    font-size: 24px;
    font-weight: 600;
  }

  .content-description {
    color: ${({ descriptionColor }) => descriptionColor};
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
  }
`;

export const WrapperTitle = styled.h2`
  color: ${({ titleColor }) => titleColor};
  font-size: 28px;
  font-weight: 800;
  line-height: 32px;
`;

export const WrapperText = styled.div`
  color: ${({ textColor }) => textColor};
  font-size: 20px;
  font-weight: 300;
  line-height: 24px;
`;

export const Image = styled.img`
  margin: 5px;
  object-fit: cover;
  width: 220px;
  height: 204px;
  border-radius: 10px;
`;