import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";

// Styles
import { Wrapper, WrapperText } from "./styles";

const Wireframe = ({ name }) => {
  const { t } = useTranslation();

  return (
    <Wrapper 
      backgroundColor="#F4766A"
      leftBackgroundImageURL="/assets/vitoria-regia/COMP00016-img.webp"
      imageCenter={false}
      id="COMP00016"
    >
      <Row
        className="g-0 h-100 col-total justify-content-center justify-content-lg-start"
      >
        <Col
          xs={12}
          sm={12}
          md={6}
          className={`d-flex flex-column justify-content-left align-items-lg-baseline col-left`}
        >
          <Col xs={12}>
            <WrapperText titleColor="#FFFFFF" dangerouslySetInnerHTML={{ __html: t(`${name}.title`) }}></WrapperText>
          </Col>
          <Col xs={12}>
            <WrapperText
              firstSubtitleColor="#FFFFFF"
              dangerouslySetInnerHTML={{ __html: t(`${name}.subtitle`) }}
            ></WrapperText>
          </Col>
          <Col
            xs={12}
            className={`d-flex justify-content-center`}
          >
            <Button
              primaryColor="#FC9742"
              secondaryColor="#FFFFFF"
              icon={false}
              buttonWidthVariable={true}
            >
              {t(`${name}.button`)}
            </Button>
          </Col>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={6}
          className="d-none d-lg-flex flex-column justify-content-center align-items-center align-items-md-start col-right"
        ></Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;
