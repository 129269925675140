import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Components
import Button from "../../Button";

// Styles
import { Wrapper, WrapperCard, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  const CARDS = t("COMP00005.cards", { returnObjects: true });

  return (
    <Wrapper 
      primaryColorTop="#FFFFFF"
      backgroundColorBottom="#FFFFFF"
      id="COMP00005"
    >
      <div className="block-top d-flex flex-column justify-content-center align-items-center">
        <Col
          xs={10}
          sm={10}
          md={10}
          lg={6}
          className="d-flex justify-content-center"
        >
          <WrapperText titleColor="#FC9742">
            <h2>{t("COMP00005.title")}</h2>
          </WrapperText>
        </Col>
        <Col
          xs={10}
          sm={10}
          md={9}
          className="d-flex justify-content-center"
        >
          <WrapperText descriptionColor="#000">
            {t("COMP00005.subTitle")}
          </WrapperText>
        </Col>
      </div>
      <div className="block-bottom" id="COMP00005-cards">
        <Row className="justify-content-center g-0 h-100">
          <Col xs={12} sm={12} md={12} lg={12} xl={10}>
            <Row className="justify-content-center g-0 h-100 row-cards">
            { _.map(CARDS, (item) => (
                  <Col
                    xs={10}
                    sm={10}
                    md={4}
                    lg={3}
                    className="d-flex justify-content-center"
                  >
                    <WrapperCard
                      mainColor="#FC9742"
                      numberColor="#FFFFFF"
                      cardBackgroundColor="#FFFFFF"
                      cardShadowColor="#FC9742"
                      descriptionColor="#000000"
                    >
                      <div className="number">{item.title}</div>
                      <div className="content">
                        <div className="content-description">
                          {item.text}
                        </div>
                      </div>
                    </WrapperCard>
                  </Col>
                ))
              }
            </Row>
          </Col>
        </Row>
        <Row className="justify-content-center g-0 w-100 h-100 mt-5">
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={8}
            className="d-flex flex-column justify-content-center text-center textBottom"
            dangerouslySetInnerHTML={{ __html: t("COMP00005.textBottom") }}
          >
          </Col>
        </Row>
        <Row className="justify-content-center g-0 w-100 h-100 row-button">
          <Col
            xs={10}
            sm={10}
            md={10}
            lg={4}
            className="d-flex justify-content-center"
          >
            <Button
              primaryColor="#FC9742"
              secondaryColor="#FFFFFF"
            >
              {t("COMP00005.button")}
            </Button>
          </Col>
        </Row>
      </div>
    </Wrapper>
  );
};

export default Wireframe;
