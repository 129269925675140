import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  
  .col-total {
    background-color: ${({ backgroundColor }) => backgroundColor};
    min-height: 780px;
  }

  .col-left {
    justify-content: space-evenly;
    @media ${devices.xs} {
      padding: 20px;
    }

    @media ${devices.sm} {
      padding: 40px;
    }

    @media ${devices.lg} {
      padding: 60px;
    }

    @media ${devices.xl} {
      padding: 60px;
    }
  }

  .col-right {
    background-image: url(${({ leftBackgroundImageURL }) => leftBackgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const WrapperText = styled.div`
  margin: 8px 0;
  ${({ titleColor }) =>
    titleColor
      ? `
    color: ${titleColor};
    font-size: 28px;
    line-height: 32px;
    font-weight: 800;

    @media ${devices.xs} {
      text-align: center;
    }

    @media ${devices.sm} {
      text-align: center;
    }

    @media ${devices.lg} {
      text-align: left;
    }
  ` : ""}

  ${({ firstSubtitleColor }) =>
    firstSubtitleColor
      ? `
    color: ${firstSubtitleColor};
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;

    @media ${devices.xs} 
      text-align: center;
    }

    @media ${devices.sm} {
      text-align: center;
    }

    @media ${devices.lg} {
      text-align: left;
    }
  ` : ""}  
`;