import i18n_translations from "../../../src/components/PropagoCheckout/i18n/translations";

const i18n = {
  "pt-BR": {
    translations: {
      Page: {
        title: "Vitória Régia - Transformando Vidas com Igualdade e Saúde",
      },
      ButtonFloating: {
        button: "Doe Agora",
        buttonBottom: "Faça uma doação",
      },
      HEAD00002: {
        title: "VITÓRIA RÉGIA",
        subTitle: "Construindo um mundo onde todos tenham voz, saúde e dignidade. Bem-vindo à Vitória Régia.",
        button: "Saiba mais",
      },
      COMP00016: {
        title: "IGUALDADE, BEM ESTAR, VOZ",
        subtitle: `<p>A história da Vitória Régia é marcada por 22 anos de ações positivas em prol do bem-estar. Nossa organização desempenha um papel crucial na promoção dos direitos humanos, saúde pública e prevenção de ISTs. Surgimos em resposta ao trágico assassinato de Nicole Velasquez, representando mulheres, LGBTQIA+ e profissionais do sexo transgêneros, promovendo cidadania por meio de educação em direitos e ações de saúde pública. Contando com a participação de Gabriela Leite, fundadora da Rede Brasileira de Prostituição, a OSC realiza treinamentos, encontros regionais e projetos de prevenção de DSTs e HIV/Aids, além de conscientizar por meio de debates, conferências e distribuição gratuita de preservativos.</p>
        <p>Recentemente, recebemos financiamento do Ministério da Saúde e da UNESCO para educar pessoas vivendo com HIV em Ribeirão Preto. Durante a pandemia de 2020, nos destacamos apoiando profissionais do sexo com testes de COVID-19, assistência legal e cestas básicas. Em 2021, participamos de um projeto internacional financiado pela Universidade de Bristol, resultando na Revista Vitória Régia e um briefing político. Atualmente, nossa organização desempenha um papel fundamental no Fórum Estadual de ONGs de AIDS de São Paulo, na Rede Nacional de Profissionais do Sexo e no Conselho Municipal de Direitos das Mulheres, reforçando nosso compromisso com a promoção dos direitos e cidadania das comunidades que atende.</p>
        <p class="text-center"><strong>Promovendo a igualdade de gênero, a saúde pública e os direitos humanos há mais de duas décadas.</strong></p>`,
        button: "CONTRIBUA PARA A PROMOÇÃO DO BEM ESTAR E SAÚDE",
      },
      COMP00005: {
        title: "ATIVIDADES DO INSTITUTO",
        subTitle: "Nossas atividades refletem o compromisso da Vitória Régia em fornecer educação, apoio e defesa de direitos para comunidades vulneráveis, promovendo a conscientização e a participação ativa em busca de uma sociedade mais justa e inclusiva. Confira algumas de nossas principais atividades: ",
        cards: [
          {
            title: 'Educação em Direitos',
            text: 'Promovemos a educação em direitos para mulheres, travestis, transexuais, profissionais do sexo e pessoas vivendo com HIV em Ribeirão Preto. Isso envolve fornecer informações essenciais sobre direitos humanos e ajudar essas comunidades a acessar políticas públicas e participar de discussões sobre direitos.',
          },
          {
            title: 'Parcerias Estratégicas',
            text: 'Nós da Vitória Régia colaboramos com importantes parceiros, incluindo o Programa Municipal de IST/AIDS, Tuberculose e Hepatites Virais, o Clã das Lobas, a OSC Arco Iris Ribeirão Preto e a Liga de Gênero, Saúde e Sexualidade da Faculdade de Medicina da USP/SP. Essas parcerias fortalecem nossa capacidade de atender às necessidades das comunidades de forma mais abrangente e eficaz.',
          },
          {
            title: "Atuação Nas Universidades",
            text: "Estendemos nossa atuação para o ambiente universitário, oferecendo palestras de conscientização, cursos de formação e participação em eventos. Isso permite que estudantes de diferentes cursos tenham acesso a informações cruciais sobre prevenção de ISTs e direitos. Além disso, colaboramos com professores e coordenadores de cursos, ajudando a responder a dúvidas e preocupações dos alunos sobre prevenção e cuidados em relação às ISTs."
          }
        ],
        textBottom: `<p><strong>Da conscientização à ação, estamos moldando um futuro mais justo e inclusivo.</strong></p>
        <p class="special-text"><strong>CRIANDO ESPERANÇA | CUIDADO | MUDANDO VIDAS</strong></p>`,
        button: "JUNTE-SE A NÓS!"
      },
      COMP00023: {
        title: '<h2>NOSSAS DIRETRIZES</h2>',
        line1: `<p>Missão</p>
        <ul>
          <li>Nossa missão é clara e impactante. Nos comprometemos a garantir o acesso à informação por meio da educação em direitos, direcionando nossos esforços para mulheres, travestis, transgêneros e profissionais do sexo na cidade de Ribeirão Preto. O objetivo principal é empoderar essas comunidades, capacitando-as para acessar políticas públicas e participar ativamente das discussões sobre direitos humanos. </li>
        </ul>`,
        line2: 
        `<p>Visão</p>
        <ul>
          <li>Temos como visão nos tornar uma organização de referência no município. Para alcançar esse objetivo, nos concentramos em diversas áreas de atuação, incluindo educação em direitos, assistência, saúde, ações de prevenção, planejamento, capacitação e consultoria para mulheres. </li>
        </ul>`
      },
      COMP00018: {
        title: "<h2>NOSSOS VALORES</h2>",
        cards: [
          {
            title: "Ética",
            subTitle: "Para nós, a ética é um valor fundamental, nossa organização age de forma íntegra, transparente e moralmente responsável em todas as nossas atividades. A ética guia todas as interações e decisões, garantindo a confiança da comunidade que atende.",
          },
          {
            title: "Reconhecimento e Respeito à Diversidade",
            subTitle: "A valorização da diversidade é um princípio essencial. Reconhecemos e respeitamos a multiplicidade de identidades, experiências e perspectivas presentes nas comunidades que atendemos. Isso cria um ambiente inclusivo e acolhedor.",
          },
          {
            title: "Inclusão",
            subTitle: "A inclusão é um valor que permeia todas as ações de nossa Organização. Nos esforçamos para garantir que todos tenham igualdade de acesso a informações, serviços e oportunidades, independentemente de sua origem, identidade de gênero ou orientação sexual."
          },
          {
            title: "Coletividade",
            subTitle: "A ideia de coletividade destaca a importância do trabalho em equipe e da colaboração. Entendemos que os desafios enfrentados pelas comunidades requerem esforços conjuntos para superá-los."
          },
          {
            title: "Cidadania",
            subTitle: "A cidadania é valorizada como um direito fundamental. Nossa Organização capacita pessoas para que compreendam seus direitos como cidadãos e as incentiva a participar ativamente da sociedade, promovendo uma cidadania ativa e consciente."
          },
          {
            title: "Liderança",
            subTitle: "A liderança é vista como um meio para inspirar e guiar as comunidades atendidas. Buscamos liderar fornecendo orientação e apoio para capacitar os outros a liderar em suas próprias vidas."
          },
          {
            title: "Inovação",
            subTitle: "A inovação é fundamental para enfrentar desafios em constante evolução. A Vitória Régia busca constantemente maneiras criativas e eficazes de atingir seus objetivos e melhorar a qualidade de vida das comunidades que atendemos."
          }
        ],
        button: "Doe agora",
      },
      COMP00004: {
        title: "NOSSOS NÚMEROS",
        cards: [
          { span: "+ 21 ANOS" },
          { 
            span: "+ 100 MULHERES AJUDADAS",
            description: "todos os anos"
          },
          { 
            span: "+ 20 VOLUNTÁRIOS",
            description: "estagiários de universidades durante o ano" 
          },
          { 
            span: "+ 03  PROJETOS SOCIAIS",
            description: "e palestras"
          },
        ],
        button: "PROMOVA A IGUALDADE CONOSCO!",
      },
      COMP00020: {
        title: "<h2>NOSSA LINHA DO TEMPO</h2>",
        subTitle: `<p>Nos últimos anos, atuamos de forma ativa durante a pandemia de COVID-19 (2020/2021). Obtivemos financiamento da Universidade de Bristol, no Reino Unido, por meio do projeto "Negociando Riscos Múltiplos: Saúde, Segurança e Bem-Estar entre Profissionais do Sexo no Brasil em Tempos de COVID-19". Atualmente estamos envolvidos em um projeto em andamento com crianças e adolescentes financiado pelo Grupo Rodonaves, denominado "Educação, Diversidade e Saúde".</p>
        <p><strong>Construindo pontes entre parcerias e pessoas. Nossa voz é sua voz.</strong></p>`,
      },
      COMP_ALLYA: {
        title: `Faça parte do `,
        description: `
          <p>Sua doação é muito importante para nós e faz toda a diferença. O processo de <strong>doação</strong> é simples, seguro e fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Você pode doar uma única vez ou todos os meses, de forma recorrente. Quer sua doação seja grande ou pequena, saiba que ela é uma prova do seu compromisso com um mundo melhor para todos. Junte-se a nós hoje na criação de um futuro onde o poder duradouro do amor prevaleça. </p>
          <p><strong>Doe todos os meses</strong> para <strong>Vitória Régia</strong> e participe do nosso <strong>Clube do Bem</strong>, uma parceria altruísta e  filantrópica entre <strong>Propago e Allya.</strong></p>
          <p>No Clube do Bem da Propago  você irá economizar dinheiro, tornar seu dia a dia mais prático e o melhor de tudo, sua economia pode virar uma doação!</p>
          <p>Você contará com uma série de <strong>descontos, vantagens e benefícios</strong> em mais de <strong>30 mil estabelecimentos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser e muito mais! </p>
        `,
        list: [
          {
            area: 'Beleza e bem-estar! -',
            discount: 'Até 80%'
          },
          {
            area: 'Lazer -',
            discount: 'Até 50% OFF'
          },
          {
            area: 'Restaurantes -',
            discount: 'Até 35% OFF'
          },
          {
            area: 'Cursos e Faculdades -',
            discount: 'Até 80%'
          },
        ], 
        bottomDescription: `Viu como sua economia pode gerar o bem para todo o ecossistema?`,
        text: `A <strong style="color: #F73D6A;">Allya</strong> tem como objetivo ajudar pessoas do bem  a manterem um padrão de vida mais econômico de forma inteligente, levando consigo a filantropia!`,
      },
      CHEC00001: {
        title: "Sua doação irá <strong>transformar a vida</strong> de alguém.",
        legend: "Apoio",
      },
      FAQS00001: {
        title: "<strong>Perguntas Frequentes</strong> sobre a doação:",
        cards: [
          {
            title: "Devo declarar as doações no imposto de renda?",
            subTitle:
              "Em conformidade com as regras da Receita Federal do Brasil, o doador precisa informar todas as doações realizadas em suas declarações financeiras. Lembrando que, segundo o Regulamento do Imposto de Renda – RIR (Decreto nº 9.580, de 22/11/ 2018), não há dedução no Imposto de Renda de Pessoa Física (IRPF) para doações a projetos como os realizados pelo Vitória Régia.<br /><br />O valor doado deve ser declarado na ficha DOAÇÕES EFETUADAS sob o código 99 – Outras doações, uma vez que se trata de doação não incentivada (não permite dedução do IR a pagar do doador).",
          },
          {
            title: "Quais são as formas de pagamento para doações no site?",
            subTitle:
              "Você pode fazer sua doação, seja ela pontual ou recorrente, via boleto bancário, cartão de crédito (todas as bandeiras) ou via pix.",
          },
          {
            title: "Por que o Vitória Régia emitiu uma cobrança em meu nome?",
            subTitle:
              "Para que os pagamentos sejam mais transparentes e seguros, a Federação Brasileira de Bancos (Febraban) instituiu que todos os boletos sejam registrados em uma plataforma de cobrança desenvolvida pela própria Febraban, que permite aos bancos informar ao cliente toda vez que um novo boleto for emitido em seu nome. Clientes cadastrados no serviço Débito Direto Autorizado (DDA) podem ser informados pelos bancos sobre a cobrança no momento do registro do boleto, pelos canais digitais ou com o envio de torpedos.",
          },
          {
            title: "Se eu não pagar o boleto, meu nome pode ser negativado?",
            subTitle:
              "Não. O pagamento dos boletos enviados pelo Vitória Régia é totalmente facultativo.",
          },
          {
            title:
              "Meu boleto veio com um valor que eu não quero doar, o que devo fazer?",
            subTitle:
              "Você pode escolher algum dos valores sugeridos para doação, ou definir uma outra quantia que seja de sua preferência, clicando no botão “outro valor”. Se o valor do boleto emitido não for igual ao que você pretende doar, pode emitir outro.",
          },
          {
            title: "O que eu faço se meu boleto vier sem código de barras?",
            subTitle:
              "Se você recebeu um boleto sem código de barras, isso indica que você está cadastrado no Débito Direto Autorizado (DDA). Por determinação das novas regras da Febraban, clientes cadastrados no serviço DDA não recebem mais boletos com códigos de barras. Para pagar seu boleto basta autorizar o débito junto ao seu banco, pela internet, pelo telefone ou nos caixas eletrônicos.",
          },
          {
            title: "O que é DDA?",
            subTitle:
              "O Débito Direto Autorizado (DDA) é um sistema criado pela Federação Brasileira de Bancos (Febraban) que substitui a emissão de boletos de cobrança impressos pela cobrança eletrônica dos pagamentos. Para ler o comunicado no site da Febraban, acesse: <a href='https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda' target='_blank'>https://portal.febraban.org.br/pagina/3051/1088/pt-br/dda</a>",
          },
          {
            title: "Como posso me cadastrar no DDA?",
            subTitle:
              "Isso é um serviço oferecido pelos bancos. Para se cadastrar no Débito Direto Autorizado (DDA), basta entrar em contato com o seu banco, por telefone, pela internet ou nos caixas eletrônicos. Mesmo após o cadastro, você continua recebendo o boleto impresso; porém, sem o código de barras.",
          },
          {
            title: "Posso me descadastrar do DDA?",
            subTitle: "Para se descadastrar, entre em contato com o seu banco.",
          },
          {
            title:
              "Quero pagar meu boleto, mas a data de vencimento passou. Preciso emitir segunda via?",
            subTitle:
              "Não. Agora os boletos vencidos podem ser pagos em qualquer banco, pela internet, nos caixas eletrônicos ou em casas lotéricas.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visite-nos!`,
        iframeTitle: "Localização de Vitória Régia em Ribeirão Preto"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Vitória Régia ® - Todos os direitos podem ser compartilhados com você.",
        copyrightSpan: "Pergunte-nos como.",
      },
      SectionCookies: {
        title: "A Vitória Régia se preocupa com você e com a sua privacidade",
        subTitle:
          "O nosso site usa cookies e outras tecnologias para personalizar a sua experiência e compreender como você e os outros visitantes usam o nosso site. Ao navegar pelo site, coletaremos tais informações para utilizá-las com estas finalidades. Caso não aceite, usaremos os cookies apenas necessários para o correto funcionamento do site",
        button: "Aceito",
      },
      Checkout: i18n_translations['pt-BR'].translations,
    },
  },
  "en-US": {
    translations: {
      Page: {
        title: "Vitória Régia - Transforming Lives with Equality and Health",
      },
      ButtonFloating: {
        button: "Donate Now",
        buttonBottom: "Make a donation",
      },
      HEAD00002: {
        title: "VITÓRIA RÉGIA",
        subTitle: "Building a world where everyone has voice, health, and dignity. Welcome to Vitória Régia.",
        button: "Learn more",
      },
COMP00016: {
        title: "EQUALITY, WELL-BEING, VOICE",
        subtitle: `<p>The history of Vitória Régia is marked by 22 years of positive actions for well-being. Our organization plays a crucial role in promoting human rights, public health, and prevention of STIs. We emerged in response to the tragic murder of Nicole Velasquez, representing women, LGBTQIA+ and transgender sex workers, promoting citizenship through rights education and public health actions. With the participation of Gabriela Leite, founder of the Brazilian Prostitution Network, the OSC conducts training, regional meetings, and STI and HIV/AIDS prevention projects, as well as raising awareness through debates, conferences, and free condom distribution.</p>
        <p>Recently, we received funding from the Ministry of Health and UNESCO to educate people living with HIV in Ribeirão Preto. During the 2020 pandemic, we stood out by supporting sex workers with COVID-19 tests, legal assistance, and basic food baskets. In 2021, we participated in an international project funded by the University of Bristol, resulting in the Vitória Régia Magazine and a political briefing. Currently, our organization plays a key role in the State Forum of AIDS NGOs in São Paulo, the National Network of Sex Workers, and the Municipal Council of Women's Rights, reinforcing our commitment to promoting the rights and citizenship of the communities we serve.</p>
        <p class="text-center"><strong>Promoting gender equality, public health, and human rights for over two decades.</strong></p>`,
        button: "CONTRIBUTE TO THE PROMOTION OF WELL-BEING AND HEALTH",
      },
      COMP00005: {
        title: "INSTITUTE ACTIVITIES",
        subTitle: "Our activities reflect Vitória Régia's commitment to providing education, support, and rights advocacy for vulnerable communities, promoting awareness and active participation in pursuit of a more just and inclusive society. Check out some of our main activities: ",
        cards: [
          {
            title: 'Rights Education',
            text: 'We promote rights education for women, transvestites, transsexuals, sex workers, and people living with HIV in Ribeirão Preto. This involves providing essential information about human rights and helping these communities access public policies and participate in rights discussions.',
          },
          {
            title: 'Strategic Partnerships',
            text: 'We at Vitória Régia collaborate with important partners, including the Municipal Program of STI/AIDS, Tuberculosis and Viral Hepatitis, the Clan of Wolves, the OSC Arco Iris Ribeirão Preto, and the Gender, Health and Sexuality League of the Faculty of Medicine of USP/SP. These partnerships strengthen our ability to meet the needs of communities more comprehensively and effectively.',
          },
          {
            title: "University Outreach",
            text: "We extend our outreach to the university environment, offering awareness lectures, training courses, and participation in events. This allows students from different courses to have access to crucial information about STI prevention and rights. In addition, we collaborate with professors and course coordinators, helping to answer student questions and concerns about STI prevention and care."
          }
        ],
        textBottom: `<p><strong>From awareness to action, we are shaping a fairer and more inclusive future.</strong></p>
        <p class="special-text"><strong>GIVING HOPE | CARING | CHANGING LIVES</strong></p>`,
        button: "JOIN US!",
      },
      COMP00023: {
        title: '<h2>OUR GUIDELINES</h2>',
        line1: `<p>Mission</p>
        <ul>
          <li>Our mission is clear and impactful. We are committed to ensuring access to information through rights education, directing our efforts towards women, transvestites, transgender people and sex workers in the city of Ribeirão Preto. The main objective is to empower these communities, enabling them to access public policies and actively participate in discussions about human rights. </li>
        </ul>`,
        line2: 
        `<p>Vision</p>
        <ul>
          <li>We envision ourselves becoming a reference organization in the municipality. To achieve this goal, we focus on various areas of operation, including rights education, assistance, health, prevention actions, planning, training and consulting for women. </li>
        </ul>`
      },
      COMP00018: {
        title: "<h2>OUR VALUES</h2>",
        cards: [
          {
            title: "Ethics",
            subTitle: "For us, ethics is a fundamental value, our organization acts in an integral, transparent and morally responsible way in all our activities. Ethics guides all interactions and decisions, ensuring the trust of the community we serve.",
          },
          {
            title: "Recognition and Respect for Diversity",
            subTitle: "The appreciation of diversity is an essential principle. We recognize and respect the multiplicity of identities, experiences and perspectives present in the communities we serve. This creates an inclusive and welcoming environment.",
          },
          {
            title: "Inclusion",
            subTitle: "Inclusion is a value that permeates all actions of our Organization. We strive to ensure that everyone has equal access to information, services and opportunities, regardless of their origin, gender identity or sexual orientation."
          },
          {
            title: "Collectivity",
            subTitle: "The idea of collectivity highlights the importance of teamwork and collaboration. We understand that the challenges faced by communities require joint efforts to overcome them."
          },
          {
            title: "Citizenship",
            subTitle: "Citizenship is valued as a fundamental right. Our Organization empowers people to understand their rights as citizens and encourages them to actively participate in society, promoting active and conscious citizenship."
          },
          {
            title: "Leadership",
            subTitle: "Leadership is seen as a means to inspire and guide the communities served. We seek to lead by providing guidance and support to empower others to lead in their own lives."
          },
          {
            title: "Innovation",
            subTitle: "Innovation is fundamental to face constantly evolving challenges. Vitória Régia constantly seeks creative and effective ways to achieve its objectives and improve the quality of life of the communities we serve."
          }
        ],
        button: "Donate now",
      },
      COMP00004: {
        title: "OUR NUMBERS",
        cards: [
          { span: "+ 21 YEARS" },
          { 
            span: "+ 100 WOMEN HELPED",
            description: "every year"
          },
          { 
            span: "+ 20 VOLUNTEERS",
            description: "university interns during the year" 
          },
          { 
            span: "+ 03 SOCIAL PROJECTS",
            description: "and lectures"
          },
        ],
        button: "PROMOTE EQUALITY WITH US!",
      },      
      COMP00020: {
        title: "<h2>OUR TIMELINE</h2>",
        subTitle: `<p>In recent years, we have been actively involved during the COVID-19 pandemic (2020/2021). We obtained funding from the University of Bristol, in the United Kingdom, through the project "Negotiating Multiple Risks: Health, Safety and Well-being among Sex Workers in Brazil in Times of COVID-19". We are currently involved in an ongoing project with children and adolescents funded by the Rodonaves Group, called "Education, Diversity and Health".</p>
        <p><strong>Building bridges between partnerships and people. Our voice is your voice.</strong></p>`,
      },      
      COMP_ALLYA: {
        title: 'Join the ',
        description: `
          <p>Your donation is very important to us and makes all the difference. The process of <strong>donation</strong> is simple, secure, and easy. The donated money is reverted to our social and philanthropic actions. You can donate once or every month, recurrently. Whether your donation is big or small, know that it is proof of your commitment to a better world for everyone. Join us today in creating a future where the lasting power of love prevails.</p>
          <p><strong>Donate every month</strong> to <strong>Vitória Régia</strong> and participate in our <strong>Clube do Bem</strong> (Club of Good), an altruistic and philanthropic partnership between <strong>Propago and Allya.</strong></p>
          <p>In Propago's Clube do Bem you will save money, make your day-to-day more practical and best of all, your savings can turn into a donation!</p>
          <p>You will have a series of <strong>discounts, advantages, and benefits</strong> in more than <strong>30 thousand establishments</strong> such as: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser and much more!</p>
        `,
        list: [
          {
            area: 'Beauty and well-being! -',
            discount: 'Up to 80% OFF'
          },
          {
            area: 'Leisure - ',
            discount: 'Up to 50% OFF'
          },
          {
            area: 'Restaurants -',
            discount: 'Up to 35% OFF'
          },
          {
            area: 'Courses and Undergraduations -',
            discount: 'Up to 80% OFF'
          },
        ], 
        bottomDescription: `<p>See how your economy can do good for the whole ecosystem?</p>`,
        text: `<strong style="color: #F73D6A;">Allya</strong> aims to aims to help good people maintain a more economical standard of living in an intelligent way, bringing philanthropy with it!`,
      },
      CHEC00001: {
        title: "Your donation will <strong>change someone's life</strong>",
        legend: "By",
      },
      FAQS00001: {
        title: "<strong>Frequently Asked Questions</strong> about the donation:",
        cards: [
          {
            title: "Does Vitória Régia accept donations in kind",
            subTitle:
              "Unfortunately, we cannot accept donations in kind.",
          },
          {
            title: "How can i be sure my online donation has been accepted?",
            subTitle:
              "If you entered a valid email address, you will receive a message confirming your donation. If you don't receive an email within about 15 minutes, please contact us.",
          },
          {
            title: "What are the payment methods for donations on the website?",
            subTitle: "You can make your donation, whether punctual or recurring by credit card."
          },
          {
            title:"Why is monthly giving the best option?",
            subTitle:
              "Monthly giving is the best option for both Vitória Régia and our supporters. It allows us to have a dependable base of support and provides predictable funding. For our supporters it is the easiest way to donate, since your credit card deducts your giving automatically.",
          },
          {
            title: "Is the donation page secure?",
            subTitle: "We are proud to meet strict data security standards in order to process your donation online.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Visit us!`,
        iframeTitle: "Localization of Vitória Régia's office in Ribeirão Preto - SP (Brazil)"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Vitória Régia ® - All rights can be shared with you.",
        copyrightSpan: "Ask us how.",
      },
      SectionCookies: {
        title: "Vitória Régia worries about your privacy.",
        subTitle:
          "This website uses cookies and other technologies to improve your experience and to analyze the traffic in our website. Once you are browsing we will colect such information for this analysis. If you don't accept it, we will use only cookies that are needed to run the website.",
        button: "I Accept",
      },
      Checkout: i18n_translations.en.translations,
    }
  },
  es: {
    translations: {
      Page: {
        title: "Vitória Régia - Transformar Vidas con Igualdad y Salud",
      },
      ButtonFloating: {
        button: "Dona ahora",
        buttonBottom: "Haz una donácion",
      },
      HEAD00002: {
        title: "VITÓRIA RÉGIA",
        subTitle: "Construyendo un mundo donde todos tengan voz, salud y dignidad. Bienvenido a Vitória Régia.",
        button: "Saber más",
      },
      COMP00016: {
        title: "IGUALDAD, BIENESTAR, VOZ",
        subtitle: `<p>La historia de Vitória Régia está marcada por 22 años de acciones positivas en pro del bienestar. Nuestra organización juega un papel crucial en la promoción de los derechos humanos, la salud pública y la prevención de las ITS. Surgimos en respuesta al trágico asesinato de Nicole Velasquez, representando a mujeres, LGBTQIA+ y trabajadores sexuales transgénero, promoviendo la ciudadanía a través de la educación en derechos y acciones de salud pública. Con la participación de Gabriela Leite, fundadora de la Red Brasileña de Prostitución, la OSC realiza capacitaciones, encuentros regionales y proyectos de prevención de ETS y VIH/Sida, además de concienciar a través de debates, conferencias y distribución gratuita de preservativos.</p>
        <p>Recientemente, recibimos financiación del Ministerio de Salud y de la UNESCO para educar a las personas que viven con VIH en Ribeirão Preto. Durante la pandemia de 2020, nos destacamos apoyando a los trabajadores sexuales con pruebas de COVID-19, asistencia legal y canastas básicas. En 2021, participamos en un proyecto internacional financiado por la Universidad de Bristol, que resultó en la Revista Vitória Régia y un informe político. Actualmente, nuestra organización juega un papel fundamental en el Foro Estatal de ONGs de SIDA de São Paulo, en la Red Nacional de Trabajadores Sexuales y en el Consejo Municipal de Derechos de las Mujeres, reforzando nuestro compromiso con la promoción de los derechos y la ciudadanía de las comunidades a las que servimos.</p>
        <p class="text-center"><strong>Promoviendo la igualdad de género, la salud pública y los derechos humanos durante más de dos décadas.</strong></p>`,
        button: "CONTRIBUYA A LA PROMOCIÓN DEL BIENESTAR Y LA SALUD",
      },
      COMP00005: {
        title: "ACTIVIDADES DEL INSTITUTO",
        subTitle: "Nuestras actividades reflejan el compromiso de Vitória Régia en proporcionar educación, apoyo y defensa de los derechos para las comunidades vulnerables, promoviendo la conciencia y la participación activa en busca de una sociedad más justa e inclusiva. Echa un vistazo a algunas de nuestras principales actividades: ",
        cards: [
          {
            title: 'Educación en Derechos',
            text: 'Promovemos la educación en derechos para mujeres, travestis, transexuales, trabajadores sexuales y personas que viven con VIH en Ribeirão Preto. Esto implica proporcionar información esencial sobre los derechos humanos y ayudar a estas comunidades a acceder a las políticas públicas y participar en discusiones sobre derechos.',
          },
          {
            title: 'Alianzas Estratégicas',
            text: 'En Vitória Régia colaboramos con importantes socios, incluyendo el Programa Municipal de ITS/SIDA, Tuberculosis y Hepatitis Virales, el Clã das Lobas, la OSC Arco Iris Ribeirão Preto y la Liga de Género, Salud y Sexualidad de la Facultad de Medicina de la USP/SP. Estas alianzas fortalecen nuestra capacidad para atender las necesidades de las comunidades de manera más completa y efectiva.',
          },
          {
            title: "Actuación en las Universidades",
            text: "Extendemos nuestra actuación al entorno universitario, ofreciendo charlas de concienciación, cursos de formación y participación en eventos. Esto permite que los estudiantes de diferentes cursos tengan acceso a información crucial sobre la prevención de las ITS y los derechos. Además, colaboramos con profesores y coordinadores de cursos, ayudando a responder a las dudas y preocupaciones de los alumnos sobre la prevención y el cuidado en relación a las ITS."
          }
        ],
        textBottom: `<p><strong>De la concienciación a la acción, estamos moldeando un futuro más justo e inclusivo.</strong></p>
        <p class="special-text"><strong>CREANDO ESPERANZA | CUIDADO | CAMBIANDO VIDAS</strong></p>`,
        button: "ÚNETE A NOSOTROS"
      },      
      COMP00023: {
        title: '<h2>NUESTRAS DIRECTRICES</h2>',
        line1: `<p>Misión</p>
        <ul>
          <li>Nuestra misión es clara e impactante. Nos comprometemos a garantizar el acceso a la información a través de la educación en derechos, dirigiendo nuestros esfuerzos hacia mujeres, travestis, transgéneros y profesionales del sexo en la ciudad de Ribeirão Preto. El objetivo principal es empoderar a estas comunidades, capacitándolas para acceder a políticas públicas y participar activamente en las discusiones sobre derechos humanos. </li>
        </ul>`,
        line2: 
        `<p>Visión</p>
        <ul>
          <li>Tenemos la visión de convertirnos en una organización de referencia en el municipio. Para lograr este objetivo, nos enfocamos en diversas áreas de actuación, incluyendo educación en derechos, asistencia, salud, acciones de prevención, planificación, capacitación y consultoría para mujeres. </li>
        </ul>`
      },
      COMP00018: {
        title: "<h2>NUESTROS VALORES</h2>",
        cards: [
          {
            title: "Ética",
            subTitle: "Para nosotros, la ética es un valor fundamental, nuestra organización actúa de manera íntegra, transparente y moralmente responsable en todas nuestras actividades. La ética guía todas las interacciones y decisiones, garantizando la confianza de la comunidad a la que servimos.",
          },
          {
            title: "Reconocimiento y Respeto a la Diversidad",
            subTitle: "La valorización de la diversidad es un principio esencial. Reconocemos y respetamos la multiplicidad de identidades, experiencias y perspectivas presentes en las comunidades a las que servimos. Esto crea un ambiente inclusivo y acogedor.",
          },
          {
            title: "Inclusión",
            subTitle: "La inclusión es un valor que impregna todas las acciones de nuestra Organización. Nos esforzamos por garantizar que todos tengan igualdad de acceso a información, servicios y oportunidades, independientemente de su origen, identidad de género u orientación sexual."
          },
          {
            title: "Colectividad",
            subTitle: "La idea de colectividad destaca la importancia del trabajo en equipo y la colaboración. Entendemos que los desafíos enfrentados por las comunidades requieren esfuerzos conjuntos para superarlos."
          },
          {
            title: "Ciudadanía",
            subTitle: "La ciudadanía es valorada como un derecho fundamental. Nuestra Organización capacita a las personas para que comprendan sus derechos como ciudadanos y las alienta a participar activamente en la sociedad, promoviendo una ciudadanía activa y consciente."
          },
          {
            title: "Liderazgo",
            subTitle: "El liderazgo se ve como un medio para inspirar y guiar a las comunidades atendidas. Buscamos liderar proporcionando orientación y apoyo para capacitar a otros a liderar en sus propias vidas."
          },
          {
            title: "Innovación",
            subTitle: "La innovación es fundamental para enfrentar desafíos en constante evolución. Vitória Régia busca constantemente formas creativas y efectivas de alcanzar sus objetivos y mejorar la calidad de vida de las comunidades a las que servimos."
          }
        ],
        button: "Donar ahora",
      },
      COMP00004: {
        title: "NUESTROS NÚMEROS",
        cards: [
          { span: "+ 21 AÑOS" },
          { 
            span: "+ 100 MUJERES AYUDADAS",
            description: "todos los años"
          },
          { 
            span: "+ 20 VOLUNTARIOS",
            description: "pasantes de universidades durante el año" 
          },
          { 
            span: "+ 03 PROYECTOS SOCIALES",
            description: "y charlas"
          },
        ],
        button: "PROMUEVE LA IGUALDAD CON NOSOTROS!",
      },      
      COMP00020: {
        title: "<h2>NUESTRA LÍNEA DE TIEMPO</h2>",
        subTitle: `<p>En los últimos años, hemos actuado de manera activa durante la pandemia de COVID-19 (2020/2021). Obtuvimos financiación de la Universidad de Bristol, en el Reino Unido, a través del proyecto "Negociando Riesgos Múltiples: Salud, Seguridad y Bienestar entre Profesionales del Sexo en Brasil en Tiempos de COVID-19". Actualmente estamos involucrados en un proyecto en curso con niños y adolescentes financiado por el Grupo Rodonaves, denominado "Educación, Diversidad y Salud".</p>
        <p><strong>Construyendo puentes entre alianzas y personas. Nuestra voz es tu voz.</strong></p>`,
      },      
      COMP_ALLYA: {
        title: '¡Venga y forme parte de nuestro ecosistema del bien!',
        description: `
          <p>Su donación es muy importante para nosotros y marca la diferencia. El proceso de donación es sencillo, seguro y fácil. O dinheiro doado é revertido para as nossas ações sociais e filantrópicas. Puede donar una vez o cada mes de forma periódica. Tanto si su donación es grande como pequeña, sepa que es una prueba de su compromiso con un mundo mejor para todos. Únase hoy a nosotros para crear un futuro en el que prevalezca el poder perdurable del amor.</p>
          <p><strong>Dona todos los meses a Vitória Régia</strong> y participa en nuestro <strong>Clube do Bem</strong>, una asociación altruista y filantrópica entre <strong>Propago y Allya.</strong></p>
          <p>Con el Clube do Bem de Propago ahorrarás dinero, harás tu día a día más práctico y lo mejor de todo, ¡tu ahorro puede convertirse en donación!</p>
          <p>¡Disfrutarás de una <strong>serie de descuentos</strong>, ventajas y beneficios en <strong>más de 30.000 establecimientos</strong> como: Netshoes, Magazine Luiza, Cinemark, Droga Raia, EspaçoLaser y muchos más!</p>
        `,
        list: [
          {
            area: 'Belleza y bienestar -',
            discount: 'Hasta el 80% de descuento', 
          },
          {
            area: 'Ocio -',
            discount: 'Hasta el 50% de descuento', 
          },
          {
            area: 'Restaurantes -',
            discount: 'Hasta el 35% de descuento', 
          },
          {
            area: 'Cursos y Universidades -',
            discount: 'Hasta el 80% de descuento', 
          },
        ],
        bottomDescription: `<p>¿Ves cómo tu economía puede hacer bien a todo el ecosistema?</p>`,
        text: `El objetivo de <strong style="color: #F73D6A;">Allya</strong> es generar ahorro y practicidad en la vida cotidiana. Con sus ventajas, es posible un estilo de vida más económico e inteligente`,
      },
      CHEC00001: {
        title: "Su donación <strong>transformará la vida</strong> de alguien.",
        legend: "Apoyo",
      },
      FAQS00001: {
        title: "<strong>Preguntas frecuentes</strong> sobre la donación:",
        cards: [
          {
            title: "¿Vitória Régia acepta donaciones en especie (ropa, alimentos, agua, etc.)?",
            subTitle:
              "Desafortunadamente, no podemos aceptar donaciones en especie.",
          },
          {
            title: "¿Cómo puedo estar seguro de que mi donación en línea ha sido aceptada?",
            subTitle:
              "Si ingresaste una dirección de correo electrónico válida, recibirás un mensaje de confirmación de tu donación. Si no recibes un correo electrónico en 15 minutos, contáctenos.",
          },
          {
            title: "¿Cuáles son los métodos de pago para las donaciones en el sitio web?",
            subTitle:
              "Puedes hacer tu donación, ya sea puntual o recurrente con tarjeta de crédito.",
          },
          {
            title: "¿Por qué la donación recurrente es la mejor opción?",
            subTitle:
              "La donación mensual es la mejor opción tanto para Vitória Régia como para nuestros seguidores. Ella nos permite tener una base de apoyo confiable y proporciona financiamiento predecible. Para nuestros seguidores, es la forma más fácil de donar, ya que la tarjeta de crédito descuenta la donación automáticamente..",
          },
          {
            title:
              "¿Es segura la página de donaciones?",
            subTitle:
              "Estamos orgullosos de cumplir con estrictos estándares de seguridad de datos para procesar tu donación en línea.",
          },
        ],
      },
      COMP_GEOL: {
        title: `Haga una visita a nosotros!`,
        iframeTitle: "Ubicación de la oficina de Vitória Régia"
      },
      FOOT00001: {
        copyright:
          "Copyright © 2023 - Vitória Régia® - Todos los derechos pueden ser compartidos con usted.",
        copyrightSpan: "Pregúntanos cómo.",
      },
      SectionCookies: {
        title: "Vitória Régia preocupase contigo e tu privacidad.",
        subTitle:
          "Este sitio web utiliza cookies y otras tecnologias para mejorar y optimizar la experiencia del usuario, bien como para analizar la atividade de los visitantes y su frequência de utilización. Si no aceptas, utilizaremos únicamente las cookies con finalidad técnica, para que la página web funcione.",
        button: "Acepto",
      },
      Checkout: i18n_translations.es.translations,
    }
  },
};

export default i18n;