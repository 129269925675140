import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  .block-top {
    padding-top: 60px;
  }

  .block-bottom {
    background-color: ${({ backgroundColorBottom }) => backgroundColorBottom};
    margin-top: 80px;
    padding-bottom: 60px;
  }
  
  .textBottom {
    font-size: 20px;
    line-height: 24px;
    font-weight: 600;

    .special-text {
      font-size: 1.2em;
      color: #F4766A;
    }
  }
  
  .row-cards {
    display: flex;
    align-items: center;
    gap: 5em;
    flex-wrap: wrap;
  }

  .row-button {
    margin-top: 10px;
  }
`

export const WrapperCard = styled.div`
  margin: 0 20px;
  box-shadow: 0px 4px 16px ${({ cardShadowColor }) => cardShadowColor};
  border-radius: 12px;

  .number {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px 10px;
    margin-top: -35px;
    width: 300px;
    border-radius: 10px 10px 0 0;
    background-color: ${({ mainColor }) => mainColor};
    color ${({ numberColor }) => numberColor};
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    min-height: 310px;
    padding: 15px;
    border-radius: 10px;
    background-color: ${({ cardBackgroundColor }) => cardBackgroundColor};
    text-align: center;
  }
  .content-description {
    color: ${({ descriptionColor }) => descriptionColor};
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
  }
`

export const WrapperText = styled.div`
  ${({ titleColor }) => titleColor ? `
    h2 {
      margin-bottom: 15px;
      color: ${titleColor};
      font-size: 28px;
      line-height: 32px;
      font-weight: 800;
      text-align: center;
    }
  ` : ''}

  ${({ descriptionColor }) => descriptionColor ? `
    margin-bottom: 0;
    color: ${descriptionColor};
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
  ` : ''}
`