// Components
import { useTranslation } from "react-i18next";
import { ReactComponent as ClubeDoBemICon } from "../../../assets/svgs/clubeDoBem.svg";
import { ReactComponent as SmileIcon } from "../../../assets/svgs/beleza.svg";
import { ReactComponent as SunIcon } from "../../../assets/svgs/lazer.svg";
import { ReactComponent as ForkAndKnifeIcon } from "../../../assets/svgs/comer.svg"; 
import { ReactComponent as CapIcon } from "../../../assets/svgs/estudos.svg";

// Styles
import { Wrapper, WrapperTitle, RowContainer, Column, WrapperDescription, Box } from "./styles";

const Wireframe = () => {
    const { t } = useTranslation();

    return (
        <Wrapper>
            <WrapperTitle>{t("COMP_ALLYA.title")} <ClubeDoBemICon width={80} height={80}/></WrapperTitle>
            <WrapperDescription dangerouslySetInnerHTML={{ __html: t("COMP_ALLYA.description") }}></WrapperDescription>
            <RowContainer>
                <Box className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center justify-content-center">
                        <SmileIcon/>
                    </div>
                    <div className="text-center">
                        {t("COMP_ALLYA.list.0.area")} <strong>{t("COMP_ALLYA.list.0.discount")}</strong>
                    </div>
                </Box>
                <Box className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center justify-content-center">
                        <SunIcon/>
                    </div>
                    <div className="text-center">
                        {t("COMP_ALLYA.list.1.area")} <strong>{t("COMP_ALLYA.list.1.discount")}</strong>
                    </div>
                </Box>
                <Box className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center justify-content-center">
                        <ForkAndKnifeIcon/>
                    </div>
                    <div className="text-center">
                        {t("COMP_ALLYA.list.2.area")} <strong>{t("COMP_ALLYA.list.2.discount")}</strong>
                    </div>
                </Box>
                <Box className="d-flex flex-column gap-2">
                    <div className="d-flex align-items-center justify-content-center">
                        <CapIcon/>
                    </div>
                    <div className="text-center">
                        {t("COMP_ALLYA.list.3.area")} <strong>{t("COMP_ALLYA.list.3.discount")}</strong>
                    </div>
                </Box>
            </RowContainer>
            <WrapperDescription className="mb-3" dangerouslySetInnerHTML={{ __html: t("COMP_ALLYA.bottomDescription") }}></WrapperDescription>
            <RowContainer>
                <Column>
                    <img src="/assets/ALLYA.webp" width={200} height={200} alt='' loading="lazy"/>
                </Column>
                <Column dangerouslySetInnerHTML={{ __html: t("COMP_ALLYA.text") }}>
                </Column>
            </RowContainer>
        </Wrapper>
    );
}

export default Wireframe; 