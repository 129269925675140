import { Col, Row, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";

// Assets
import { ReactComponent as FacebookIcon } from "../../../assets/svgs/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../../assets/svgs/instagram.svg";
import { ReactComponent as WhatsappIcon } from "../../../assets/svgs/whatsapp.svg";

// Styles
import { Wrapper, WrapperText } from "./styles";

const Wireframe = () => {
  const { t } = useTranslation();

  return (
    <Wrapper 
      backgroundColor="#F4766A" 
      copyrightBackgroundColor="#000000" 
      socialIconsColor="#FFFFFF" 
    >
      <Row className="justify-content-center g-0 h-100">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <Row className="justify-content-center g-0 h-100">
            <Col
              xs={12}
              lg={3}
              className="d-flex flex-lg-column justify-content-center"
            >
              <div>
                <Image 
                  className="image" 
                  src="/assets/vitoria-regia/FOOT00001-img.webp" 
                  width={270} 
                  height={200} 
                  alt=''
                  fluid 
                />
              </div>
            </Col>
            <Col
              xs={12}
              lg={8}
              xl={7}
              className="d-flex flex-column justify-content-center wrapper-content"
            >
              <div>
                <WrapperText mainColor="#FFFFFF" textRegular={200}>
                  VITÓRIA RÉGIA
                </WrapperText>
                <WrapperText mainColor="#FFFFFF" textRegular={200}>
                  CNPJ: 04.860.239/0001-92
                </WrapperText>
                <WrapperText mainColor="#FFFFFF" textRegular={200}>
                  <a
                    href="mailto:oscvitoriaregia@yahoo.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    oscvitoriaregia@yahoo.com
                  </a>
                </WrapperText>
                <WrapperText mainColor="#FFFFFF" textRegular={200}>
                  +55 (16) 99638-7064
                </WrapperText>
              </div>
              <div className="d-flex justify-content-center justify-content-lg-end align-items-lg-end wrapper-icons">
                <a
                  href="https://web.facebook.com/oscvitoriaregia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.instagram.com/ongvitoriaregia/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://wa.me/5516996387064"
                  target="_blank"
                  rel="noreferrer"
                >
                  <WhatsappIcon />
                </a>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="justify-content-center g-0 h-100 wrapper-copyright">
        <Col xs={10} sm={10} md={10} lg={10} xl={10}>
          <WrapperText copyrightColor="#FFFFFF" textRegular={400}>
            {t("FOOT00001.copyright")}{" "}
            <a
              href="https://propago.com.br/"
              target="_blank"
              rel="noreferrer"
            >
              {t("FOOT00001.copyrightSpan")}
            </a>
          </WrapperText>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Wireframe;