import styled from "styled-components";
import devices from "../../../utils/helperGrids";

export const Wrapper = styled.div`
  position: relative;
  
  .left {
    background-image: url(${({ leftBackgroundImageURL }) => leftBackgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
  }
  
  .right {
    padding: 60px 0;
    background-color: #F4766A;
    @media ${devices.xs} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media ${devices.sm} {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    @media ${devices.md} {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 40px;
    }
  }

  .button {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }
`;

export const WrapperText = styled.div`
  ${({ titleColor }) => titleColor ? `
    h2 {
      margin: 0 auto 20px;
      color: ${titleColor};
      font-weight: 700;
    }

    @media ${devices.xs} {
      font-size: 28px;
    }

    @media ${devices.sm} {
      font-size: 28px;
    }

    @media ${devices.md} {
      font-size: 35px;
    }
  `: ""}

  ${({ contentColor }) => contentColor ? `
    color: ${contentColor};
    font-weight: 700;

    @media ${devices.xs} {
      font-size: 18px;
    }

    @media ${devices.sm} {
      font-size: 18px;
    }

    @media ${devices.md} {
      font-size: 18px;
    }

    ul {
      font-weight: normal;
    }
  `: ""}
`;