import styled from "styled-components";

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;

  ${({ backgroundImageURL }) => backgroundImageURL ? `
    background-image: url(${backgroundImageURL});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  ` : `
    background-color: ${({ backgroundColor }) => backgroundColor}
  `}

  .button {
    display: flex;
    justify-content: center;
  }
`;

export const WrapperText = styled.div`
  ${({ titleColor }) =>
    titleColor
      ? `
      h2 { 
        font-size: 28px;
        line-height: 32px;
        font-weight: 800;
        color: ${titleColor};
        text-align: center;
        margin-bottom: 15px;
      }
  ` : ""}

  ${({ descriptionColor }) =>
    descriptionColor
      ? `
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
    color: ${descriptionColor};
    text-align: center;
  ` : ""}
`;